import * as React from "react"
import Layout from "../components/layout"

export default function NotFound() {
    return (
        <Layout>
            <h1>Page Not Found</h1>
            <p className='lead'>We couldn't find anything at this page.</p>
        </Layout>
    )
}
